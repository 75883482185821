<template>
	<div class="flex flex-wrap px-3 py-4">
		<section
			v-if="hasReviews"
			id="review-overall"
			class="flex flex-col items-center w-full mb-4 md:w-56 min-h-80"
		>
			<div class="flex flex-col justify-center w-48 h-48 mb-4 text-center text-white bg-green-500 rounded-full align-center review-score-background-image">
				<div class="mb-2 text-5xl font-bold">
					{{ reviewAverage }}
				</div>
				<div class="uppercase">
					{{ numberOfReviews }}
				</div>
			</div>
			<div class="grid w-full grid-cols-2 gap-4 mx-auto min-h-64 md:grid-cols-1 justify-evenly justify-items-center content-evenly">
				<div
					v-for="type in ratingTypes"
					:key="type.key"
					class="flex flex-col content-center justify-center md:w-full min-h-13 min-w-36"
				>
					<div class="h-full mx-auto min-w-33">
						<div class="w-full mb-1 text-sm font-bold uppercase">
							{{ type.name }}
						</div>
						<StarRating
							v-if="isMounted"
							:rating="getRating(type.key)"
							:increment="0.1"
							:read-only="true"
							:star-size="20"
							:padding="6"
							:show-rating="false"
						/>
					</div>
				</div>
			</div>
		</section>

		<section
			v-if="hasReviews"
			class="w-full md:flex-1"
		>
			<BusinessReviewForm
				:business="reviewsPageData"
				class="p-4 mb-4 border border-gray-200 rounded-lg shadow-md"
				@review-submitted="refetchReviews"
			/>
			<section
				class="w-full md:flex-1"
			>
				<div v-if="showReviews">
					<TransitionGroup
						appear
						name="list-slide-up"
					>
						<BusinessReview
							v-for="(review, index) in reviews"
							:key="review.id"
							:review="review"
							:style="{'--index': index, '--perPage': BUSINESS_REVIEWS_QUERY_LIMIT, '--page': page}"
							:listing-id="parseInt(review.listing?.id)"
							@open-edit-modal="openEditModal"
							@open-report-modal="openReportModal"
							@open-delete-modal="openDeleteModal"
						/>
					</TransitionGroup>

					<ReportReviewModal
						:modal-id="REPORT_REVIEW_MODAL"
						:review-to-report="reviewToReport"
					/>

					<EditReviewModal
						profile="business"
						:rating-types="ratingTypes"
						:review-ratings="reviewRatings"
						:review-edit="editData"
						:business="reviewsPageData"
						@update-review="handleUpdateReview"
					/>

					<DeleteReviewModal
						:id="DELETE_REVIEW_MODAL"
						:listing-name="reviewsPageData?.name"
						:review-id="reviewToDelete?.id"
						:listing-id="reviewToDelete?.listingId"
						@delete-review="handleDeleteReview"
					/>
				</div>
			</section>
		</section>

		<div
			v-if="showLoading"
			class="grid w-full grid-cols-1 gap-16 py-16 text-xl font-bold text-center"
		>
			<div class="w-full text-gray-300">
				Loading...
			</div>
			<LoadingSpinner class="w-1/4 mx-auto text-gray-300 md:w-1/12" />
		</div>
		<div
			v-if="showEmptyState"
			class="w-full max-w-3xl py-4 m-auto"
		>
			<div class="mx-auto">
				<hr class="mb-4">
				<BusinessReviewForm
					v-if="reviewsPageData"
					:business="reviewsPageData"
					@review-submitted="refetchReviews"
				/>
			</div>
			<EmptyList
				:image-src="ReviewEmptyStateImage"
				copy="This business has no reviews yet. Be the first!"
				class="mb-4"
			/>
		</div>
	</div>
</template>

<script async>
import { mapGetters, mapMutations } from 'vuex'

import ReviewEmptyStateImage from '@/assets/empty-states/no-reviews.webp'
import BusinessReview from '@/components/business/BusinessReview.vue'
import BusinessReviewForm from '@/components/business/BusinessReviewForm.vue'
import EmptyList from '@/components/multiUse/EmptyList.vue'
import LoadingSpinner from '@/components/multiUse/LoadingSpinner.vue'
import { DELETE_REVIEW_MODAL, EDIT_REVIEW_MODAL, REPORT_REVIEW_MODAL } from '@/constants/modal/names.js'
import { ERROR, SUCCESS } from '@/constants/toast/type.js'
import { DeleteListingReview, UpdateListingReview } from '@/gql/business/mutations.gql'
import { GetBusinessReviewsPageData } from '@/gql/business/queries/pages.gql'
import { componentLevelGQLErrors } from '@/utils/error-handling.js'
import { mapRatingsObjectToArray, mapRatingTypes, setReviewInput } from '@/utils/reviewMappers.js'

const BUSINESS_REVIEWS_QUERY_LIMIT = 8

export default {
	components: {
		BusinessReview,
		BusinessReviewForm,
		EmptyList,
		StarRating: () => import('vue-star-rating'),
		LoadingSpinner,
		EditReviewModal: () => import('@/components/UI/modals/EditReviewModal.vue'),
		ReportReviewModal: () => import('@/components/UI/modals/ReportReviewModal.vue'),
		DeleteReviewModal: () => import('@/components/UI/modals/DeleteReviewModal.vue')
	},
	data() {
		return {
			REPORT_REVIEW_MODAL,
			EDIT_REVIEW_MODAL,
			DELETE_REVIEW_MODAL,
			ratings: {},
			isMounted: false,
			profile: 'business',
			editData: {},
			reviewToReport: {
				review: '',
				review_id: 0,
				listing_id: 0
			},
			reviewToDelete: {
				id: 0,
				listingId: 0
			},
			reviewRatings: {},
			ratingIndex: '',
			ReviewEmptyStateImage,
			observed: false,
			observer: null,
			loading: 0,
			page: 1,
			BUSINESS_REVIEWS_QUERY_LIMIT
		}
	},
	apollo: {
		reviewsPageData: {
			query: GetBusinessReviewsPageData,
			update(data) {
				if (data.listing) {
					return {
						...data.listing,
						paging: data.paging
					}
				}
			},
			variables() {
				return {
					reviewsLimit: BUSINESS_REVIEWS_QUERY_LIMIT,
					id: this.businessId
				}
			},
			skip() {
				return !this.businessId
			},
			error(error) {
				componentLevelGQLErrors(error)
			}
		}
	},
	computed: {
		...mapGetters('business', [ 'businessId' ]),
		numberOfReviews() {
			return `From ${this.reviewsPageData?.reviewCount || 0} ${this.reviewsPageData?.reviewCount === 1 ? 'Review' : 'Reviews'}`
		},
		ratingTypes() {
			return mapRatingTypes(this.reviewsPageData?.businessType) || []
		},
		reviewAverage() {
			return parseFloat(this.reviewsPageData?.ratingOverall)?.toFixed(1) || '0.0'
		},
		hasReviews () {
			return !!this.reviewsPageData?.reviews?.length
		},
		reviews() {
			return this.reviewsPageData?.reviews
		},
		observerIndex() {
			return this.reviews?.length - (BUSINESS_REVIEWS_QUERY_LIMIT / 2) || 0
		},
		reviewsOffset() {
			return this.reviews?.length
		},
		showReviews() {
			return this.hasReviews
		},
		showLoading() {
			return this.loading || !this.businessId
		},
		showEmptyState() {
			return !this.hasReviews && !this.loading && this.businessId
		},
		listingId() {
			return this.reviewsPageData?.id || 0
		}
	},
	watch: {
		reviewsOffset(newValue) {
			if (this.reviewsPageData?.reviewCount > newValue) {
				this.observeReviews()
			}
		},
		observed() {
			if (this.observed) {
				this.page++
				this.fetchMoreReviews()
				this.observed = false
			}
		}
	},
	mounted() {
		this.isMounted = true
	},
	beforeDestroy() {
		if (this.observer) {
			this.observer.disconnect()
		}
	},
	methods: {
		...mapMutations('modal', [ 'showModal' ]),
		...mapMutations('toast', [ 'showToast' ]),
		openEditModal(data) {
			this.$data.editData = data
			const editId = parseInt(this.editData.id)
			const reviewToEdit = this.reviews.find(review => parseInt(review.id) === editId)
			this.ratingTypes.forEach(type => {
				if (type.key && reviewToEdit?.ratings?.find(rating => rating.key === type.key)?.rating) {
					const score = parseInt(reviewToEdit.ratings.find(rating => rating.key === type.key).rating)
					this.reviewRatings = Object.assign({}, this.reviewRatings, { [type.key]: score })
				}
			})
			this.showModal(EDIT_REVIEW_MODAL)
		},
		openReportModal(data) {
			this.reviewToReport.review = data.review
			this.reviewToReport.review_id = parseInt(data.id)
			this.reviewToReport.listing_id = parseInt(this.reviewsPageData.id)
			this.showModal(REPORT_REVIEW_MODAL)
		},
		openDeleteModal(data) {
			this.reviewToDelete.id = parseInt(data.id)
			this.reviewToDelete.listingId = parseInt(data.listing?.id)
			this.showModal(DELETE_REVIEW_MODAL)
		},
		observeReviews() {
			this.$nextTick(() => {
				if (window && 'IntersectionObserver' in window) {
					const scrollTarget = document.getElementById(this.reviewsPageData.reviews[this.observerIndex]?.id)
					if (scrollTarget) {
						this.observer = new IntersectionObserver(entries => {
							entries.forEach(entry => {
								if (entry.isIntersecting) {
									this.observed = true
									this.observer.disconnect()
								}
							})
						})
						this.observer.observe(scrollTarget)
					}
				} else {
					this.observed = true
				}
			})
		},
		async fetchMoreReviews() {
			try {
				await this.$apollo.queries.reviewsPageData.fetchMore({
					variables: {
						reviewsOffset: this.reviewsOffset,
						reviewsLimit: BUSINESS_REVIEWS_QUERY_LIMIT
					},
					updateQuery(previousResult, { fetchMoreResult }) {
						if (!fetchMoreResult) {
							return previousResult
						}
						const newReviews = fetchMoreResult.listing.reviews
						return {
							__typename: previousResult.listing.__typename,
							listing:
								{
									__typename: previousResult.listing.__typename,
									id: previousResult.listing.id,
									businessType: previousResult.listing.businessType,
									ratingOverall: previousResult.listing.ratingOverall,
									ratings: previousResult.listing.ratings,
									reviewCount: previousResult.listing.reviewCount,
									reviews: [ ...previousResult.listing.reviews, ...newReviews ]
								}
						}
					}
				})
			} catch (error) {
				componentLevelGQLErrors(error)
			}
		},
		refetchReviews() {
			this.$apollo.queries.reviewsPageData.refetch()
		},
		getRating(ratingKey) {
			return this.reviewsPageData?.ratings?.find(rating => rating.key === ratingKey)?.rating || 0
		},
		handleUpdateReview(id, review, ratings) {
			this.$apollo.mutate({
				mutation: UpdateListingReview,
				variables: {
					id: parseInt(id),
					reviewInput: setReviewInput(review, ratings)
				},
				update: (store, { data: { listingReviewUpdate } }) => {
					const { listing } = store.readQuery({
						query: GetBusinessReviewsPageData,
						variables: {
							seoUrl: this.$route.params.business || '',
							chainSeoUrl: this.$route.params.chain || '',
							reviewsLimit: BUSINESS_REVIEWS_QUERY_LIMIT,
							id: this.businessId
						}
					})
					const reviewsCopy = listing.reviews
					const ratingsTypename = reviewsCopy[0]?.ratings?.[0]?.__typename || ''
					const ratingsArray = mapRatingsObjectToArray(ratings, ratingsTypename)
					const { overallRating } = listingReviewUpdate
					const reviewToUpdate = reviewsCopy.find(review => review.id == id)
					const reviewIndex = reviewsCopy.map(review => review.id).indexOf(id.toString())
					const updatedReview = { ...reviewToUpdate, review, ratings: ratingsArray, overallRating }
					reviewsCopy.splice(reviewIndex, 1, updatedReview)
					const updatedListing = { ...listing, reviews: reviewsCopy }
					store.writeQuery({ query: GetBusinessReviewsPageData, data: { listing: updatedListing } })
				},
				optimisticResponse: {
					__typename: 'Mutation',
					listingReviewUpdate: {
						__typename: 'ListingReviewEdit',
						id: null,
						overallRating: 0,
						review
					}
				}
			}).then(() => {
				this.showToast({
					primaryText: 'Review Updated',
					type: SUCCESS
				})
			}).catch((error) => {
				this.showToast({
					primaryText: 'Invalid Review',
					secondaryText: error,
					type: ERROR
				})
				componentLevelGQLErrors(error)
			})
		},
		handleDeleteReview(reviewId, listingId) {
			this.$apollo.mutate({
				mutation: DeleteListingReview,
				variables: {
					reviewId,
					listingId
				},
				update: (store) => {
					const { listing } = store.readQuery({
						query: GetBusinessReviewsPageData,
						variables: {
							seoUrl: this.$route.params.business || '',
							chainSeoUrl: this.$route.params.chain || '',
							reviewsLimit: BUSINESS_REVIEWS_QUERY_LIMIT,
							id: this.businessId
						}
					})
					const reviewIndex = listing.reviews.map(review => review.id).indexOf(reviewId.toString())
					listing.reviews.splice(reviewIndex, 1)
				}
			}).then(() => {
				this.showToast({
					primaryText: 'Review Deleted',
					type: SUCCESS
				})
			}).catch((error) => {
				this.showToast({
					primaryText: 'Deletion Failed',
					secondaryText: 'error',
					type: ERROR
				})
				componentLevelGQLErrors(error)
			})
		}
	}
}
</script>
