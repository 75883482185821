<template>
	<div class="w-full">
		<form
			ref="reviewForm"
			v-require-login="{callback: handleReviewPost}"
			class="flex flex-col"
		>
			<h2 class="mb-2 text-3xl font-bold">
				Write a review
			</h2>
			<textarea
				v-model.trim="review"
				placeholder="Describe your experience"
				required
				class="w-full p-2 mb-4 transition duration-100 bg-gray-200 border-0 border-gray-300 rounded min-h-36 focus:ring focus:ring-green-300"
			/>
			<div class="flex flex-wrap pb-4 space-y-4 min-h-80 md:min-h-40 md:space-y-0">
				<div
					v-for="ratingType in ratingTypes"
					:key="ratingType.key"
					class="w-full md:w-1/3 min-h-5"
				>
					<div class="mb-1 text-sm font-bold uppercase">
						{{ ratingType.name }}
					</div>
					<StarRating
						v-if="isMounted"
						v-model="newRatings[ratingType.key]"
						:star-size="20"
						:padding="6"
						:show-rating="false"
					/>
				</div>
			</div>
			<button
				type="submit"
				:disabled="isDisabled"
				:class="isDisabled ? 'bg-gray-300 cursor-not-allowed' : 'bg-green-500'"
				class="p-2 font-semibold text-white rounded w-36"
			>
				Submit Review
			</button>
		</form>

		<h4
			v-if="showSuccessMessage"
			class="mt-2"
		>
			Thank you for posting a review!
		</h4>
		<SMSModal
			@success="handleReviewPost"
		/>
	</div>
</template>

<script async>
import { mapGetters, mapMutations } from 'vuex'

import SMSModal from '@/components/UI/modals/SMSModal.vue'
import { SMS_MODAL } from '@/constants/modal/names.js'
import { ERROR } from '@/constants/toast/type.js'
import { CreateListingReview } from '@/gql/business/mutations.gql'
import { mapRatingTypes, setReviewInput } from '@/utils/reviewMappers.js'

export default {
	components: {
		StarRating: () => import('vue-star-rating'),
		SMSModal
	},
	props: {
		business: {
			type: Object,
			required: true
		}
	},
	emits: [ 'review-submitted' ],
	data() {
		return {
			review: '',
			newRatings: {},
			showSuccessMessage: false,
			isMounted: false,
			SMS_MODAL
		}
	},
	computed: {
		isDisabled() {
			return this.review.length === 0
		},
		...mapGetters('auth', [ 'auth' ]),
		ratingTypes() {
			return mapRatingTypes(this.business?.businessType) || []
		}
	},
	mounted() {
		this.isMounted = true
	},
	methods: {
		...mapMutations('modal', [ 'showModal' ]),
		...mapMutations('toast', [ 'showToast' ]),
		async handleReviewPost() {
			if (!this.auth?.verifiedPhone) {
				this.showModal(SMS_MODAL)
			} else {
				this.$apollo.mutate({
					mutation: CreateListingReview,
					variables: {
						listingId: parseInt(this.business.id),
						reviewInput: setReviewInput(this.review, this.newRatings)
					}
				}).then(() => {
					this.showSuccessMessage = true
					this.review = ''
					this.newRatings = {}
					this.$emit('review-submitted')
				}).catch((error) => {
					const message = error.message.replace('GraphQL error: ', '') // its too late for me to understand whytf this is a thing or why it wasnt handled previously
					this.showToast({
						primaryText: 'Invalid Review',
						secondaryText: message,
						type: ERROR
					})
				})
			}
		}
	}
}
</script>
