<template>
	<ReviewCard
		:review="review"
		:listing-id="listingId"
		profile="business"
		can-edit
		@open-edit-modal="openEditModal"
		@open-report-modal="openReportModal"
		@open-delete-modal="openDeleteModal"
	/>
</template>

<script async>
import ReviewCard from '@/components/multiUse/ReviewCard.vue'

export default {
	components: {
		ReviewCard
	},
	props: {
		review: {
			type: Object,
			required: true
		},
		listingId: {
			type: Number,
			required: true
		}
	},
	emits: [ 'open-report-modal', 'open-edit-modal', 'open-delete-modal' ],
	methods: {
		openEditModal() { // emits to BusinessReviews
			this.$emit('open-edit-modal', this.review)
		},
		openReportModal() { // emits to BusinessReviews
			this.$emit('open-report-modal', this.review)
		},
		openDeleteModal() {
			this.$emit('open-delete-modal', this.review)
		}
	}
}
</script>
